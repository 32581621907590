import React from 'react';

import cx from 'classnames';

import css from './Loaders.module.css';

interface LoaderProps {
  color?: string;
  size?: number;

  className?: string;
}

export const LoaderBouncing = React.memo<LoaderProps>(
  ({ color = 'var(--yellow)', size = 56, className }) => {
    return (
      <div className={cx(css.wrapper, className)} style={{ width: size }}>
        <svg className={css.svg} stroke={color} viewBox="25 25 50 50">
          <circle className={css.circle} cx={50} cy={50} r={20} />
        </svg>
      </div>
    );
  },
);

interface LoaderLazyProps {
  className?: string;
  color?: string;
}
export const LoaderLazy = React.memo<LoaderLazyProps>(
  ({ className, color = 'var(--yellow)' }) => {
    return (
      <div className={cx(css.centered, className)}>
        <LoaderBouncing color={color} />
      </div>
    );
  },
);

export const LoadMessagesSpinner = () => {
  return (
    <div className={css.loadMessagesContainer}>
      <div className={css.loadMessagesWrapper}>
        <div className={css.loadMessages} />
      </div>
    </div>
  );
};
