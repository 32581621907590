import * as GA from 'analyticsHelpers';

const category = 'footer';

const EVENT_CLICK_DISCORD = 'clickDiscord';
const EVENT_CLICK_TWITTER = 'clickTwitter';
const EVENT_CLICK_LANGUAGE = 'clickLanguage';
const EVENT_CLICK_ADD_BOT = 'clickAddBot';

export const footer = {
  [EVENT_CLICK_DISCORD]: (): void => {
    GA.event({ action: EVENT_CLICK_DISCORD, category });
  },
  [EVENT_CLICK_TWITTER]: (): void => {
    GA.event({ action: EVENT_CLICK_TWITTER, category });
  },
  [EVENT_CLICK_LANGUAGE]: (): void => {
    GA.event({ action: EVENT_CLICK_LANGUAGE, category });
  },
  [EVENT_CLICK_ADD_BOT]: (category?: 'footer'): void => {
    GA.event({ action: EVENT_CLICK_ADD_BOT, category });
  },
};
