import * as GA from 'analyticsHelpers';
import { api } from 'api';

type Category = 'groupFeed' | 'slots';

const CLICK_EVENT_BUTTON = 'clickEventButton';

export const calendarEvents = {
  [CLICK_EVENT_BUTTON]: (
    category: Category,
    event: api.Maybe<api.Event>,
    actionType: 'join' | 'leave' | 'view',
  ): void => {
    event &&
      GA.event({
        action: CLICK_EVENT_BUTTON,
        category,
        additionalParams: {
          game: event.gameId,
          groupId: event.id,
          eventType: event.type,
          actionType,
        },
      });
  },
};
