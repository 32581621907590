import * as GA from 'analyticsHelpers';
import { EventPage } from 'analyticsHelpers';

import { api } from '../api';

import { Slide } from '../components/SlideoverSettings/Desktop';

const userSettingsCategory = 'settingsUserTab';
const gameSettingsCategory = 'settingsGameTab';
const profileSettingsCategory = 'settingsProfileTab';
const linksSettingsCategory = 'linksTabSettings';

const EVENT_CLICK_CONNECT_DISCORD = 'clickConnectDiscord';
const EVENT_CLICK_CONNECT_TWITCH = 'clickConnectTwitch';
const EVENT_CLICK_CONNECT_BATTLENET_EU = 'clickConnectBattlenetEu';
const EVENT_CLICK_CONNECT_BATTLENET_US = 'clickConnectBattlenetUs';
const EVENT_CLICK_CONNECT_STEAM = 'clickConnectSteam';
const EVENT_CLICK_CONNECT_RIOT = 'clickConnectRiot';
const EVENT_SETTINGS_TAB = 'clickSettingsTab';
const EVENT_CLICK_LOGOUT = 'clickLogout';
const EVENT_LOGOUT = 'logout';
const EVENT_CANCEL_LOGOUT = 'cancelLogout';
const SAVE_GAME_SETTINGS = 'saveGameSettings';
const EVENT_CLICK_ADD_LINK = 'clickAddLink';
const EVENT_CLICK_DELETE_LINK = 'clickDeleteLink';
const EVENT_CLICK_CREATE_LINK = 'clickCreateLink';

function withUserParams(
  user: api.User,
  obj: Record<string, string> = {},
): Record<string, string> {
  return {
    profileUserId: user.id,
    profileUserName: user.username,
    ...obj,
  };
}

export const settings = {
  [EVENT_CLICK_CREATE_LINK]: (user: api.Maybe<api.User>): void => {
    if (!user) {
      return;
    }

    GA.event({
      action: EVENT_CLICK_CREATE_LINK,
      category: linksSettingsCategory,
      eventPage: 'settingsPage',
      additionalParams: withUserParams(user),
    });
  },
  [EVENT_CLICK_DELETE_LINK]: (page: EventPage): void => {
    GA.event({
      action: EVENT_CLICK_DELETE_LINK,
      category: page === 'profilePage' ? 'linksTab' : linksSettingsCategory,
      eventPage: page,
    });
  },
  [EVENT_CLICK_ADD_LINK]: (page: EventPage): void => {
    GA.event({
      action: EVENT_CLICK_ADD_LINK,
      category: page === 'profilePage' ? 'linksTab' : linksSettingsCategory,
      eventPage: page,
    });
  },
  [EVENT_SETTINGS_TAB]: (tab: Slide): void => {
    GA.event({
      action: EVENT_SETTINGS_TAB,
      category: profileSettingsCategory,
      eventPage: 'settingsPage',
      additionalParams: {
        tab: tab.toString(),
      },
    });
  },
  [SAVE_GAME_SETTINGS]: (): void => {
    GA.event({
      action: SAVE_GAME_SETTINGS,
      category: gameSettingsCategory,
      eventPage: 'settingsPage',
    });
  },
  [EVENT_CLICK_CONNECT_DISCORD]: (): void => {
    GA.event({
      action: EVENT_CLICK_CONNECT_DISCORD,
      category: userSettingsCategory,
      eventPage: 'settingsPage',
    });
  },
  [EVENT_CLICK_CONNECT_TWITCH]: (): void => {
    GA.event({
      action: EVENT_CLICK_CONNECT_TWITCH,
      category: userSettingsCategory,
      eventPage: 'settingsPage',
    });
  },
  [EVENT_CLICK_CONNECT_BATTLENET_EU]: (): void => {
    GA.event({
      action: EVENT_CLICK_CONNECT_BATTLENET_EU,
      category: userSettingsCategory,
      eventPage: 'settingsPage',
    });
  },
  [EVENT_CLICK_CONNECT_BATTLENET_US]: (): void => {
    GA.event({
      action: EVENT_CLICK_CONNECT_BATTLENET_US,
      category: userSettingsCategory,
      eventPage: 'settingsPage',
    });
  },
  [EVENT_CLICK_CONNECT_STEAM]: (): void => {
    GA.event({
      action: EVENT_CLICK_CONNECT_STEAM,
      category: userSettingsCategory,
      eventPage: 'settingsPage',
    });
  },
  [EVENT_CLICK_CONNECT_RIOT]: (): void => {
    GA.event({
      action: EVENT_CLICK_CONNECT_RIOT,
      category: userSettingsCategory,
      eventPage: 'settingsPage',
    });
  },
  [EVENT_CLICK_LOGOUT]: (): void => {
    GA.event({
      action: EVENT_CLICK_LOGOUT,
      category: userSettingsCategory,
      eventPage: 'settingsPage',
    });
  },
  [EVENT_LOGOUT]: (): void => {
    GA.event({
      action: EVENT_LOGOUT,
      category: userSettingsCategory,
      eventPage: 'settingsPage',
    });
  },
  [EVENT_CANCEL_LOGOUT]: (): void => {
    GA.event({
      action: EVENT_CANCEL_LOGOUT,
      category: userSettingsCategory,
      eventPage: 'settingsPage',
    });
  },
  clickEditGame: (gameId?: string): void => {
    if (!gameId) {
      return;
    }

    GA.event({
      action: `${gameId}Click`,
      category: gameSettingsCategory,
      eventPage: 'settingsPage',
    });
  },
};
