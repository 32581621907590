import * as GA from 'analyticsHelpers';
import { api } from 'api';

import { EventCategory } from './groupFeed';
export type PlayerCategory =
  | 'playerFeed'
  | 'groupFeed'
  | 'followingTab'
  | 'followersTab'
  | 'visitorsTab'
  | 'profileBlock'
  | 'slots'
  | 'slotWaitlist'
  | EventCategory;
export type GameCardCategory =
  | 'playerFeed'
  | 'playerProfileTab'
  | 'editGame'
  | 'waitlist';

const category = 'playerFeed';
const playerProfileCategory = 'playerProfileTab';

const EVENT_CLICK_PLAYERS = 'clickPlayer';
const EVENT_CLICK_CHARACTER_COPYLINK = 'clickCharacterCopylink';
const EVENT_CLICK_INVITE = 'clickInvite';
const EVENT_CLICK_FOLLOW = 'clickFollow';
const EVENT_CLICK_UNFOLLOW = 'clickUnfollow';
const EVENT_DELETE_CHARACTER = 'deleteCharacter';
const EVENT_CLICK_COPY_DISCORD = 'clickCopyDiscord';

interface PlayerEvent {
  profileId: string;
  profileUsername: string;
  group?: string;
  isStreamer?: string;
}

export const playerFeed = {
  [EVENT_CLICK_PLAYERS]: ({
    additionalParams,
    category,
  }: GA.Event & { category: PlayerCategory } & {
    additionalParams: PlayerEvent;
  }): void => {
    GA.event({
      action: EVENT_CLICK_PLAYERS,
      category,
      additionalParams,
    });
  },
  [EVENT_CLICK_CHARACTER_COPYLINK]: ({
    additionalParams,
    category,
  }: GA.Event & { category: GameCardCategory } & {
    additionalParams: PlayerEvent;
  }): void => {
    const page = (): GA.EventPage | undefined => {
      switch (category) {
        case 'editGame':
          return 'gameSettingsPage';
        case 'waitlist':
          return 'waitlistPage';
        default:
          undefined;
      }
    };
    GA.event({
      action: EVENT_CLICK_CHARACTER_COPYLINK,
      category,
      additionalParams,
      eventPage: page(),
    });
  },
  [EVENT_CLICK_INVITE]: ({
    additionalParams,
  }: GA.Event & { additionalParams: PlayerEvent }): void => {
    GA.event({
      action: EVENT_CLICK_INVITE,
      category,
      additionalParams,
    });
  },
  [EVENT_CLICK_FOLLOW]: ({
    additionalParams,
    category,
  }: GA.Event & { category: PlayerCategory } & {
    additionalParams: PlayerEvent;
  }): void => {
    GA.event({
      action: EVENT_CLICK_FOLLOW,
      category,
      additionalParams,
    });
  },
  [EVENT_CLICK_UNFOLLOW]: ({
    additionalParams,
    category,
  }: GA.Event & { category: PlayerCategory } & {
    additionalParams: PlayerEvent;
  }): void => {
    GA.event({
      action: EVENT_CLICK_UNFOLLOW,
      category,
      additionalParams,
    });
  },
  [EVENT_CLICK_COPY_DISCORD]: (
    eventPage: GA.EventPage,
    game: api.Maybe<api.GameId>,
    userId: string,
  ): void => {
    GA.event({
      action: EVENT_CLICK_COPY_DISCORD,
      eventPage,
      category: 'playerFeed',
      additionalParams: {
        ...(game && { game: game }),
        profile: userId,
      },
    });
  },
  [EVENT_DELETE_CHARACTER]: (characterId: string): void => {
    GA.event({
      action: EVENT_DELETE_CHARACTER,
      category: playerProfileCategory,
      eventPage: 'settingsPage',
      additionalParams: {
        characterId,
      },
    });
  },
  clickPlayersFilter: (): void => {
    GA.event({
      action: 'clickFilter',
      category: 'playerFeed',
    });
  },
};
