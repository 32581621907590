import * as GA from 'analyticsHelpers';

import type { SupportedGameId } from 'api/types';

const MY_GAME_CATEGORY = 'yourGames';
const LFG_GAMES_CATEGORY = 'lfgGames';
const CLICK_PLAYERS_TAB = 'clickPlayersTab';
const CLICK_GROUPS_TAB = 'clickGroupsTab';
const CLICK_LADDERS_TAB = 'clickLaddersTab';
const CLICK_VACANCIES_TAB = 'clickVacanciesTab';
const CLICK_TEAMS_TAB = 'clickTeamsTab';

export const myGames = {
  myGameClick: (gameId: SupportedGameId): void => {
    if (!gameId) {
      return;
    }

    GA.event({
      action: `click${gameId}`,
      category: MY_GAME_CATEGORY,
    });
  },

  lfgGameClick: (gameId: SupportedGameId): void => {
    if (!gameId) {
      return;
    }

    GA.event({
      action: `click${gameId}`,
      category: LFG_GAMES_CATEGORY,
    });
  },

  [CLICK_PLAYERS_TAB]: (gameId: SupportedGameId): void => {
    GA.event({
      additionalParams: { game: gameId },
      action: CLICK_PLAYERS_TAB,
      category: 'header',
    });
  },
  [CLICK_GROUPS_TAB]: (gameId: SupportedGameId): void => {
    GA.event({
      additionalParams: { game: gameId },
      action: CLICK_GROUPS_TAB,
      category: 'header',
    });
  },
  [CLICK_LADDERS_TAB]: (gameId: SupportedGameId): void => {
    GA.event({
      additionalParams: { game: gameId },
      action: CLICK_LADDERS_TAB,
      category: 'header',
    });
  },
  [CLICK_VACANCIES_TAB]: (gameId: SupportedGameId): void => {
    GA.event({
      additionalParams: { game: gameId },
      action: CLICK_VACANCIES_TAB,
      category: 'header',
    });
  },
  [CLICK_TEAMS_TAB]: (gameId: SupportedGameId): void => {
    GA.event({
      additionalParams: { game: gameId },
      action: CLICK_TEAMS_TAB,
      category: 'header',
    });
  },
};
