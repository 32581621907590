import * as GA from 'analyticsHelpers';

const category = 'modals';

const EVENT_OPEN_APP_LINKS_MODAL = 'openAppLinksModal';
const EVENT_CLICK_ON_APP_LINK = 'clickOnAppLink';

export const modals = {
  [EVENT_OPEN_APP_LINKS_MODAL]: (): void => {
    GA.event({ action: EVENT_OPEN_APP_LINKS_MODAL, category });
  },
  [EVENT_CLICK_ON_APP_LINK]: (mobileAppOs: 'ios' | 'android'): void => {
    GA.event({
      action: EVENT_CLICK_ON_APP_LINK,
      category,
      additionalParams: { mobileAppOs },
    });
  },
};
