import * as GA from 'analyticsHelpers';

type InviteCategory = 'rightBar' | 'notifications';

const EVENT_ACCEPT_INVITE = 'clickAcceptInvite';
const EVENT_REJECT_INVITE = 'clickRejectInvite';

export const rightBar = {
  [EVENT_ACCEPT_INVITE]: (category: InviteCategory, groupId: string): void => {
    GA.event({
      category,
      action: EVENT_ACCEPT_INVITE,
      additionalParams: { groupId },
    });
  },
  [EVENT_REJECT_INVITE]: (category: InviteCategory, groupId: string): void => {
    GA.event({
      category,
      action: EVENT_REJECT_INVITE,
      additionalParams: { groupId },
    });
  },
};
