import * as GA from 'analyticsHelpers';

const category = 'slots';

const EVENT_ADD_SLOT = 'addSlot';
const EVENT_DELETE_SLOT = 'deleteSlot';
const EVENT_CLICK_SERVER_INVITE = 'clickServerInvite';
const EVENT_CLICK_CHANNEL_LINK = 'clickChannelLink';
const EVENT_CLICK_CARD_LINK = 'clickCardLink';
const EVENT_CLICK_JOIN = 'clickJoin';
const EVENT_CLICK_OPEN_WAITLIST = 'clickOpenWaitlis';

export const slots = {
  [EVENT_ADD_SLOT]: (groupId: string, gameId: string): void => {
    GA.event({
      action: EVENT_ADD_SLOT,
      category,
      eventPage: 'groupPage',
      additionalParams: {
        groupId,
        gameId,
      },
    });
  },
  [EVENT_CLICK_OPEN_WAITLIST]: (groupId: string, gameId: string): void => {
    GA.event({
      action: EVENT_CLICK_OPEN_WAITLIST,
      category,
      eventPage: 'groupPage',
      additionalParams: {
        groupId,
        gameId,
      },
    });
  },
  [EVENT_CLICK_JOIN]: (groupId: string, gameId: string): void => {
    GA.event({
      action: EVENT_CLICK_JOIN,
      category,
      eventPage: 'groupPage',
      additionalParams: {
        groupId,
        gameId,
      },
    });
  },
  [EVENT_CLICK_CARD_LINK]: (groupId: string, gameId: string): void => {
    GA.event({
      action: EVENT_CLICK_CARD_LINK,
      category,
      eventPage: 'groupPage',
      additionalParams: {
        groupId,
        gameId,
      },
    });
  },
  [EVENT_CLICK_CHANNEL_LINK]: (groupId: string, gameId: string): void => {
    GA.event({
      action: EVENT_CLICK_CHANNEL_LINK,
      category,
      eventPage: 'groupPage',
      additionalParams: {
        groupId,
        gameId,
      },
    });
  },
  [EVENT_CLICK_SERVER_INVITE]: (groupId: string, gameId: string): void => {
    GA.event({
      action: EVENT_CLICK_SERVER_INVITE,
      category,
      eventPage: 'groupPage',
      additionalParams: {
        groupId,
        gameId,
      },
    });
  },
  [EVENT_DELETE_SLOT]: (groupId: string, gameId: string): void => {
    GA.event({
      action: EVENT_DELETE_SLOT,
      category,
      eventPage: 'groupPage',
      additionalParams: {
        groupId,
        gameId,
      },
    });
  },
};
