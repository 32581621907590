import * as GA from 'analyticsHelpers';

const EVENT_BROWSE_ALL_GAMES = 'clickBrowseAllGames';
const EVENT_CLICK_TRY_IT = 'clickTryIt';
const EVENT_CLICK_EXPLORE_LFG = 'clickExploreLfg';

export const welcome = {
  [EVENT_BROWSE_ALL_GAMES]: (gameId?: string): void => {
    GA.event({
      action: EVENT_BROWSE_ALL_GAMES,
      eventPage: 'welcomePage',
      additionalParams: {
        game: gameId || '',
      },
    });
  },
  [EVENT_CLICK_TRY_IT]: (gameId?: string): void => {
    GA.event({
      action: EVENT_CLICK_TRY_IT,
      eventPage: 'welcomePage',
      additionalParams: {
        game: gameId || '',
      },
    });
  },
  [EVENT_CLICK_EXPLORE_LFG]: (gameId?: string): void => {
    GA.event({
      action: EVENT_CLICK_EXPLORE_LFG,
      eventPage: 'welcomePage',
      additionalParams: {
        game: gameId || '',
      },
    });
  },
};
