import * as GA from 'analyticsHelpers';

export type GameSettingsCategory = 'settingsGame' | 'onboarding';
type ConnectStatus = 'succes' | 'error';
type Slide = 'game_settings';
const setPage = (category: GameSettingsCategory): GA.EventPage =>
  category === 'onboarding' ? 'loginPopup' : 'gameSettingsPage';

const CLICK_CONNECT_LOL = 'clicConnectLol';
const CLICK_REFRESH_LOL = 'clickRefreshLol';
const CLICK_DISCONNECT_LOL = 'clickDisconnectLol';

const CLICK_ADD_LOSTARK = 'clickAddLostark';

const EVENT_CLICK_BACK = 'clickBack';
const EVENT_CLICK_CONTINUE = 'clickContinue';
const EVENT_CLICK_BROWSE_GROUPS = 'clickBrowseGroups';
const EVENT_CLICK_BROWSE_PLAYERS = 'clickBrowsePlayers';
const EVENT_CLICK_ADD_GAME = 'clickAddGame';
const EVENT_CLICK_FIND_TEAMMATES = 'clickFindTeammates';
const EVENT_CLICK_CHECK_WHATS_HAPPENING = 'clickCheckWhatsHappening';

export const onboarding = {
  [CLICK_CONNECT_LOL]: (
    category: GameSettingsCategory,
    status: ConnectStatus,
  ): void => {
    GA.event({
      action: CLICK_CONNECT_LOL,
      eventPage: setPage(category),
      category,
      additionalParams: {
        status,
      },
    });
  },
  [CLICK_REFRESH_LOL]: (
    category: GameSettingsCategory,
    status: ConnectStatus,
  ): void => {
    GA.event({
      action: CLICK_REFRESH_LOL,
      eventPage: setPage(category),
      category,
      additionalParams: {
        status,
      },
    });
  },
  [CLICK_DISCONNECT_LOL]: (
    category: GameSettingsCategory,
    status: ConnectStatus,
  ): void => {
    GA.event({
      action: CLICK_DISCONNECT_LOL,
      eventPage: setPage(category),
      category,
      additionalParams: {
        status,
      },
    });
  },
  [CLICK_ADD_LOSTARK]: (category: GameSettingsCategory): void => {
    GA.event({
      action: CLICK_ADD_LOSTARK,
      eventPage: setPage(category),
      category,
    });
  },

  [EVENT_CLICK_BACK]: (
    category: GameSettingsCategory,
    currentSlide: Slide,
    gameId: string,
  ): void => {
    GA.event({
      action: EVENT_CLICK_BACK,
      category,
      eventPage: setPage(category),
      additionalParams: {
        currentSlide,
        game: gameId,
      },
    });
  },
  [EVENT_CLICK_CONTINUE]: (
    category: GameSettingsCategory,
    currentSlide: Slide,
    gameId: string,
  ): void => {
    GA.event({
      action: EVENT_CLICK_CONTINUE,
      category,
      eventPage: setPage(category),
      additionalParams: {
        currentSlide,
        game: gameId,
      },
    });
  },
  [EVENT_CLICK_ADD_GAME]: (category: GameSettingsCategory, gameId?: string): void => {
    GA.event({
      action: EVENT_CLICK_ADD_GAME,
      category,
      eventPage: setPage(category),
      additionalParams: {
        game: gameId || '',
      },
    });
  },
  [EVENT_CLICK_BROWSE_GROUPS]: (category: GameSettingsCategory): void => {
    GA.event({
      action: EVENT_CLICK_BROWSE_GROUPS,
      eventPage: setPage(category),
      category,
    });
  },
  [EVENT_CLICK_BROWSE_PLAYERS]: (category: GameSettingsCategory): void => {
    GA.event({
      action: EVENT_CLICK_BROWSE_PLAYERS,
      eventPage: setPage(category),
      category,
    });
  },
  [EVENT_CLICK_FIND_TEAMMATES]: (category: GameSettingsCategory): void => {
    GA.event({
      action: EVENT_CLICK_FIND_TEAMMATES,
      eventPage: setPage(category),
      category,
    });
  },
  [EVENT_CLICK_CHECK_WHATS_HAPPENING]: (category: GameSettingsCategory): void => {
    GA.event({
      action: EVENT_CLICK_CHECK_WHATS_HAPPENING,
      eventPage: setPage(category),
      category,
    });
  },
};
