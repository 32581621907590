import { calendarEvents } from './calendarEvents';
import { footer } from './footer';
import { myGames } from './games';
import { groupFeed } from './groupFeed';
import { header } from './header';
import { modals } from './modals';
import { onboarding } from './onboarding';
import { playerFeed } from './playerFeed';
import { profile } from './profile';
import { rightBar } from './rightBar';
import { settings } from './settings';
import { sideNav } from './sideNav';
import { slots } from './slots';
import { waitlist } from './waitlist';
import { welcome } from './welcome';

export const analytics = {
  ...myGames,
  ...sideNav,
  ...header,
  ...rightBar,
  ...footer,
  ...groupFeed,
  ...playerFeed,
  ...profile,
  ...settings,
  ...slots,
  ...waitlist,
  ...welcome,
  ...onboarding,
  ...calendarEvents,
  ...modals,
};
