import * as GA from 'analyticsHelpers';

const category = 'sideNav';

const EVENT_CLICK_LOGO = 'clickLogo';
const EVENT_CLICK_HOME = 'clickHome';
const EVENT_CLICK_MY_GROUPS = 'clickMyGroup';
const EVENT_CLICK_NOTIFICTIONS = 'clickNotifications';
const EVENT_CLICK_PLAYERS = 'clickPlayers';

export const sideNav = {
  [EVENT_CLICK_LOGO]: (): void => {
    GA.event({ action: EVENT_CLICK_LOGO, category });
  },
  [EVENT_CLICK_HOME]: (): void => {
    GA.event({ action: EVENT_CLICK_HOME, category });
  },
  [EVENT_CLICK_MY_GROUPS]: (): void => {
    GA.event({ action: EVENT_CLICK_MY_GROUPS, category });
  },
  [EVENT_CLICK_NOTIFICTIONS]: (): void => {
    GA.event({
      action: EVENT_CLICK_NOTIFICTIONS,
      category,
    });
  },
  [EVENT_CLICK_PLAYERS]: (): void => {
    GA.event({ action: EVENT_CLICK_PLAYERS, category });
  },
};
