import * as GA from 'analyticsHelpers';
import { api } from 'api';

export type EventCategory =
  | 'groupFeed'
  | 'historyTab'
  | 'allTab'
  | 'memberTab'
  | 'ownerTab'
  | 'requestedTab'
  | 'invites';

const groupHeaderCategory = 'groupHeader';

const EVENT_CLICK_GROUP = 'clickGroup';
const EVENT_CLICK_GROUP_COPY_LINK = 'clickGroupShare';
const EVENT_CLICK_EDIT = 'clickEdit';
const EVENT_CLICK_DELETE = 'clickDelete';
const EVENT_CLICK_CREATE = 'clickCreate';
const EVENT_CLICK_PLAY = 'clickPlay';

const EVENT_CLICK_ALL_TAB = 'clickAllTab';
const EVENT_CLICK_MEMBER_TAB = 'clickMemberTab';
const EVENT_CLICK_OWNER_TAB = 'clickOwnerTab';
const EVENT_CLICK_REQUESTED_TAB = 'clickRequestedTab';
const EVENT_CLICK_LIKE_EVENT = 'clickLikeEvent';

const EVENT_MSG_SEND = 'sendMessage';

export const groupFeed = {
  [EVENT_CLICK_GROUP]: (
    category: EventCategory,
    groupId: string,
    gameId: string,
  ): void => {
    if (!groupId || !gameId) {
      return;
    }

    GA.event({
      action: EVENT_CLICK_GROUP,
      category,
      additionalParams: {
        groupId,
        game: gameId,
      },
    });
  },
  [EVENT_CLICK_LIKE_EVENT]: (additionalParams: {
    eventId: string;
    eventName: string;
  }): void => {
    GA.event({
      action: EVENT_CLICK_LIKE_EVENT,
      category: 'groupFeed',
      additionalParams,
    });
  },
  [EVENT_CLICK_PLAY]: (eventId: string, eventPage = false): void => {
    GA.event({
      action: EVENT_MSG_SEND,
      eventPage: eventPage ? 'groupPage' : undefined,
      category: 'groupFeed',
      additionalParams: {
        groupId: eventId,
      },
    });
  },
  [EVENT_MSG_SEND]: (group?: api.Event): void => {
    const additionalParams = group
      ? {
          game: group?.gameId,
          groupId: group?.id,
        }
      : undefined;

    GA.event({
      action: EVENT_MSG_SEND,
      eventPage: group?.id ? 'groupPage' : undefined,
      category: 'chat',
      additionalParams,
    });
  },
  [EVENT_CLICK_GROUP_COPY_LINK]: (
    category: EventCategory,
    groupId: string,
    gameId: string,
  ): void => {
    if (!groupId || !gameId) {
      return;
    }

    GA.event({
      action: EVENT_CLICK_GROUP_COPY_LINK,
      category,
      additionalParams: {
        groupId,
        game: gameId,
      },
    });
  },
  [EVENT_CLICK_EDIT]: (groupId: string, gameId: string): void => {
    GA.event({
      action: EVENT_CLICK_EDIT,
      eventPage: 'groupPage',
      category: groupHeaderCategory,
      additionalParams: {
        groupId,
        gameId,
      },
    });
  },
  [EVENT_CLICK_DELETE]: (groupId: string, gameId: string): void => {
    GA.event({
      action: EVENT_CLICK_DELETE,
      eventPage: 'groupPage',
      category: groupHeaderCategory,
      additionalParams: {
        groupId,
        gameId,
      },
    });
  },
  [EVENT_CLICK_CREATE]: (): void => {
    GA.event({
      action: EVENT_CLICK_CREATE,
      eventPage: 'createGroupPage',
    });
  },
  clickCreateGroupGame: (gameId: api.GameId): void => {
    GA.event({
      action: `click${gameId}`,
      eventPage: 'createGroupPage',
    });
  },
  [EVENT_CLICK_ALL_TAB]: (): void => {
    GA.event({
      action: EVENT_CLICK_ALL_TAB,
      eventPage: 'groupPage',
      category: 'groupFeed',
    });
  },
  [EVENT_CLICK_MEMBER_TAB]: (): void => {
    GA.event({
      action: EVENT_CLICK_MEMBER_TAB,
      eventPage: 'groupPage',
      category: 'groupFeed',
    });
  },
  [EVENT_CLICK_OWNER_TAB]: (): void => {
    GA.event({
      action: EVENT_CLICK_OWNER_TAB,
      eventPage: 'groupPage',
      category: 'groupFeed',
    });
  },
  [EVENT_CLICK_REQUESTED_TAB]: (): void => {
    GA.event({
      action: EVENT_CLICK_REQUESTED_TAB,
      eventPage: 'myGroupPage',
      category: 'groupFeed',
    });
  },
  clickGroupFilter: (category?: string): void => {
    GA.event({
      action: 'clickFilter',
      category: category || 'groupFeed',
    });
  },
};
