import * as GA from 'analyticsHelpers';

const category = 'profileBlock';
const linksCategory = 'linksTab';

const EVENT_CLICK_PROFILE_COPY_LINK = 'clickProfileCopylink';
const EVENT_CLICK_SETTINGS = 'clickSettings';
const EVENT_CLICK_ADD_GAMES = 'clickAddGames';
const EVENT_CLICK_DISCORD_COPYLINK = 'clickDiscordCopylink';
const EVENT_CLICK_TWITCH_COPYLINK = 'clickTwitchCopylink';
const EVENT_CLICK_BATTLENET_COPYLINK = 'clickBattlenetCopylink';
const EVENT_CLICK_TWITTER_COPYLINK = 'clickTwitterCopylink';
const EVENT_CLICK_STEAM_COPYLINK = 'clickSteamCopylink';
const EVENT_CLICK_HISTORY = 'clickHistory';
const EVENT_CLICK_FOLLOWING = 'clickFollowing';
const EVENT_CLICK_FOLLOWERS = 'clickFollowers';
const EVENT_CLICK_PLAYER_PROFILE = 'clickPlayerProfile';
const EVENT_CLICK_PLAYER_LINKS = 'clickPlayerLinks';
const EVENT_CLICK_LINK = 'clickLink';

export const profile = {
  [EVENT_CLICK_LINK]: (
    profileUserId: string,
    profileUserName: string,
    isEvent = false,
  ): void => {
    if (!profileUserId) {
      return;
    }
    GA.event({
      action: EVENT_CLICK_LINK,
      category: linksCategory,
      eventPage: isEvent ? 'groupPage' : 'profilePage',
      additionalParams: {
        profileUserId,
        profileUserName,
      },
    });
  },
  [EVENT_CLICK_PROFILE_COPY_LINK]: (
    profileUserId: string,
    profileUserName: string,
  ): void => {
    if (!profileUserId) {
      return;
    }
    GA.event({
      action: EVENT_CLICK_PROFILE_COPY_LINK,
      category,
      eventPage: 'profilePage',
      additionalParams: {
        profileUserId,
        profileUserName,
      },
    });
  },
  [EVENT_CLICK_PLAYER_PROFILE]: (
    profileUserId: string,
    profileUserName: string,
  ): void => {
    if (!profileUserId) {
      return;
    }
    GA.event({
      action: EVENT_CLICK_PLAYER_PROFILE,
      category,
      eventPage: 'profilePage',
      additionalParams: {
        profileUserId,
        profileUserName,
      },
    });
  },
  [EVENT_CLICK_PLAYER_LINKS]: (
    profileUserId: string,
    profileUserName: string,
  ): void => {
    if (!profileUserId) {
      return;
    }
    GA.event({
      action: EVENT_CLICK_PLAYER_LINKS,
      category,
      eventPage: 'profilePage',
      additionalParams: {
        profileUserId,
        profileUserName,
      },
    });
  },
  [EVENT_CLICK_FOLLOWERS]: (profileUserId: string, profileUserName: string): void => {
    if (!profileUserId) {
      return;
    }
    GA.event({
      action: EVENT_CLICK_FOLLOWERS,
      category,
      eventPage: 'profilePage',
      additionalParams: {
        profileUserId,
        profileUserName,
      },
    });
  },
  [EVENT_CLICK_FOLLOWING]: (profileUserId: string, profileUserName: string): void => {
    if (!profileUserId) {
      return;
    }

    GA.event({
      action: EVENT_CLICK_FOLLOWING,
      category,
      eventPage: 'profilePage',
      additionalParams: {
        profileUserId,
        profileUserName,
      },
    });
  },
  [EVENT_CLICK_HISTORY]: (profileUserId: string, profileUserName: string): void => {
    if (!profileUserId) {
      return;
    }
    GA.event({
      action: EVENT_CLICK_HISTORY,
      category,
      eventPage: 'profilePage',
      additionalParams: {
        profileUserId,
        profileUserName,
      },
    });
  },
  [EVENT_CLICK_BATTLENET_COPYLINK]: (
    profileUserId: string,
    profileUserName: string,
  ): void => {
    if (!profileUserId) {
      return;
    }
    GA.event({
      action: EVENT_CLICK_BATTLENET_COPYLINK,
      category,
      eventPage: 'profilePage',
      additionalParams: {
        profileUserId,
        profileUserName,
      },
    });
  },
  [EVENT_CLICK_TWITTER_COPYLINK]: (
    profileUserId: string,
    profileUserName: string,
  ): void => {
    if (!profileUserId) {
      return;
    }
    GA.event({
      action: EVENT_CLICK_TWITTER_COPYLINK,
      category,
      eventPage: 'profilePage',
      additionalParams: {
        profileUserId,
        profileUserName,
      },
    });
  },
  [EVENT_CLICK_TWITCH_COPYLINK]: (
    profileUserId: string,
    profileUserName: string,
  ): void => {
    if (!profileUserId) {
      return;
    }
    GA.event({
      action: EVENT_CLICK_TWITCH_COPYLINK,
      category,
      eventPage: 'profilePage',
      additionalParams: {
        profileUserId,
        profileUserName,
      },
    });
  },
  [EVENT_CLICK_STEAM_COPYLINK]: (
    profileUserId: string,
    profileUserName: string,
  ): void => {
    if (!profileUserId) {
      return;
    }
    GA.event({
      action: EVENT_CLICK_STEAM_COPYLINK,
      category,
      eventPage: 'profilePage',
      additionalParams: {
        profileUserId,
        profileUserName,
      },
    });
  },
  [EVENT_CLICK_DISCORD_COPYLINK]: (
    profileUserId: string,
    profileUserName: string,
  ): void => {
    if (!profileUserId) {
      return;
    }
    GA.event({
      action: EVENT_CLICK_DISCORD_COPYLINK,
      category,
      eventPage: 'profilePage',
      additionalParams: {
        profileUserId,
        profileUserName,
      },
    });
  },
  [EVENT_CLICK_ADD_GAMES]: (profileUserId: string, profileUserName: string): void => {
    if (!profileUserId) {
      return;
    }
    GA.event({
      action: EVENT_CLICK_ADD_GAMES,
      category,
      eventPage: 'profilePage',
      additionalParams: {
        profileUserId,
        profileUserName,
      },
    });
  },
  [EVENT_CLICK_SETTINGS]: (profileUserId: string, profileUserName: string): void => {
    if (!profileUserId) {
      return;
    }
    GA.event({
      action: EVENT_CLICK_SETTINGS,
      category,
      eventPage: 'profilePage',
      additionalParams: {
        profileUserId,
        profileUserName,
      },
    });
  },
};
