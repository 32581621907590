import * as GA from 'analyticsHelpers';

type CreteGroupCategory = 'header' | 'onboarding';

const category = 'header';

const EVENT_CLICK_LOGIN = 'clickLogin';
const EVENT_CLICK_CREATE_GROUP = 'clickCreateGroup';
const EVENT_LOGIN_CLICK = 'login';
const EVENT_CLICK_PROFILE = 'clickProfile';
const EVENT_LOGIN_COMPLETE_SUCCESS = 'loginCompleteSuccess';
const EVENT_LOGIN_COMPLETE_ERROR = 'loginCompleteError';
const EVENT_CLOSE_LOGIN = 'closeLogin';
const EVENT_CLICK_OTHER_OPTIONS = 'clickOtherOptions';

export type LoginMethod = 'discord' | 'battlenet' | 'twitch' | 'google' | 'facebook';

export const header = {
  [EVENT_CLICK_PROFILE]: (): void => {
    GA.event({ action: EVENT_CLICK_PROFILE, category });
  },
  [EVENT_CLICK_LOGIN]: (): void => {
    GA.event({ action: EVENT_CLICK_LOGIN, category });
  },
  [EVENT_CLICK_CREATE_GROUP]: (category: CreteGroupCategory): void => {
    GA.event({
      action: EVENT_CLICK_CREATE_GROUP,
      category,
    });
  },
  [EVENT_CLOSE_LOGIN]: (): void => {
    GA.event({
      action: EVENT_CLOSE_LOGIN,
    });
  },
  [EVENT_LOGIN_CLICK]: (method: LoginMethod): void => {
    GA.event({
      action: EVENT_LOGIN_CLICK,
      additionalParams: {
        method,
      },
    });
  },
  [EVENT_LOGIN_COMPLETE_SUCCESS]: (method: LoginMethod): void => {
    GA.event({
      action: EVENT_LOGIN_COMPLETE_SUCCESS,
      additionalParams: {
        method,
      },
    });
  },
  [EVENT_LOGIN_COMPLETE_ERROR]: (method: LoginMethod): void => {
    GA.event({
      action: EVENT_LOGIN_COMPLETE_ERROR,
      additionalParams: {
        method,
      },
    });
  },
  [EVENT_CLICK_OTHER_OPTIONS]: (): void => {
    GA.event({
      action: EVENT_CLICK_OTHER_OPTIONS,
      eventPage: 'loginPopup',
    });
  },
};
