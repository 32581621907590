import * as GA from 'analyticsHelpers';

const EVENT_CLICK_ACCEPT_USER = 'clickAcceptUser';
const EVENT_CLICK_REJECT_USER = 'clickRejectUser';
const EVENT_CLICK_RAFFLE = 'clickRaffle';

export const waitlist = {
  [EVENT_CLICK_ACCEPT_USER]: (profileId: string, profileName: string): void => {
    GA.event({
      action: EVENT_CLICK_ACCEPT_USER,
      eventPage: 'waitlistPage',
      additionalParams: {
        profileId,
        profileName,
      },
    });
  },
  [EVENT_CLICK_REJECT_USER]: (profileId: string, profileName: string): void => {
    GA.event({
      action: EVENT_CLICK_REJECT_USER,
      eventPage: 'waitlistPage',
      additionalParams: {
        profileId,
        profileName,
      },
    });
  },
  [EVENT_CLICK_RAFFLE]: (): void => {
    GA.event({
      action: EVENT_CLICK_RAFFLE,
      eventPage: 'waitlistPage',
    });
  },
};
